exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-bck-js": () => import("./../../../src/pages/blog-bck.js" /* webpackChunkName: "component---src-pages-blog-bck-js" */),
  "component---src-pages-community-bck-js": () => import("./../../../src/pages/community-bck.js" /* webpackChunkName: "component---src-pages-community-bck-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

